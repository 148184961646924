
      @import "styles/_settings";
      @import "styles/tools/_index";
      @import "styles/placeholders/_index";
    
.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  justify-content: space-evenly;
}

.logo {
  svg {
    color: var(--cl-canadaLifeRed);
    height: 36px;
    width: 36px;
  }
}

.errorMessage {
  @extend %h4;
  max-width: 47rem;
}

.errorSolution {
  max-width: 70rem;

  a {
    @extend %textLink;
  }
}
